import React from 'react';

import './index.scss';
import { RadioButton } from '../../../../common';

interface Props {
    imageUrl: string;
    title?: string;
    onClick?: () => void;
    isSelected?: boolean;
    isClickable?: boolean;
}

const ConditionOptionImage: React.FC<Props> = ({imageUrl, onClick, title, isSelected, isClickable = true}) => {
    return <div className={`conditionalImageContainer ${onClick && isClickable ? 'clickable': ''}`} onClick={() => (onClick && onClick())}>
        {
            onClick && !!isClickable && <div className="conditionalRadioWrapper">
                <RadioButton isSelected={isSelected} className={'circular'} />
		    </div>
        }
        <img src={require(`../../../../../assets/${imageUrl}`)} alt=""/>
        {title && <p>{title}</p>}
    </div>
}

export default ConditionOptionImage;
