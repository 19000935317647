import { CURRENT_DEVICE_INFO_FILLING_ACTIONS } from './types';
import Models from "../../types/models";

const selectDeviceAction = (deviceInfo: any) => {
    return {type: CURRENT_DEVICE_INFO_FILLING_ACTIONS.DEVICE_SELECTION, payload: deviceInfo}
}

const selectMemoryAction = (memoryCapacity: any) => {
    return {type: CURRENT_DEVICE_INFO_FILLING_ACTIONS.SELECT_MEMORY, payload: memoryCapacity}
}

const selectCarrierAction = (carrier: any) => {
    return {type: CURRENT_DEVICE_INFO_FILLING_ACTIONS.SELECT_CARRIER, payload: carrier}
}

const updateConditionAction = (conditionInfo: any) => {
    return {type: CURRENT_DEVICE_INFO_FILLING_ACTIONS.UPDATE_CONDITIONS, payload: conditionInfo}
}

const setInfoFormStepAction = (step: number) => {
    return {type: CURRENT_DEVICE_INFO_FILLING_ACTIONS.SET_INFO_FORM_STEP, payload: step}
}

const deleteCurrentDeviceInfoAction = () => {
    return {type: CURRENT_DEVICE_INFO_FILLING_ACTIONS.CLEAR_INFO}
}

const editCurrentDeviceStorageInfoAction = (device: Models.Device) => {
    return {type: CURRENT_DEVICE_INFO_FILLING_ACTIONS.EDIT_DEVICE_STORAGE, payload: device}
}

const editCurrentDeviceCarrierInfoAction = (device: Models.Device) => {
    return {type: CURRENT_DEVICE_INFO_FILLING_ACTIONS.EDIT_DEVICE_CARRIER, payload: device}
}

const editCurrentDeviceConditionsInfoAction = (device: Models.Device) => {
    return {type: CURRENT_DEVICE_INFO_FILLING_ACTIONS.EDIT_DEVICE_CONDITIONS, payload: device}
}

export {
    selectDeviceAction,
    deleteCurrentDeviceInfoAction,
    selectMemoryAction,
    selectCarrierAction,
    updateConditionAction,
    setInfoFormStepAction,
    editCurrentDeviceStorageInfoAction,
    editCurrentDeviceCarrierInfoAction,
    editCurrentDeviceConditionsInfoAction
};
