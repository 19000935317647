import React, { useState } from 'react';
import { ReactComponent as TipIcon } from '../../../assets/icons/tip.svg';
import OutsideClickHandler from 'react-outside-click-handler';

import './index.scss';

interface Props {
    label: string;
    isLarge?: boolean;
}

const Tip: React.FC<Props> = ({label, children, isLarge= false}) => {
    const [visible, setTipVisible] = useState(false);

    return <OutsideClickHandler onOutsideClick={() => setTipVisible(false)}>
        <div className="tip-container" onClick={() => setTipVisible(!visible)}>
            {
                !!children &&
                <div className="tip-content">
                    <div className="tip-icon">
                        <TipIcon/>
                    </div>

                    {
                        children && visible && <div className={`tip-info-content ${isLarge ? 'large' : ''}`}>
                            {children}
                        </div>
                    }
                </div>
            }
            <p className={'tip-label'}>{label}</p>
        </div>
    </OutsideClickHandler>
}

export default Tip;
