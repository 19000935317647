import React from 'react';
import './index.scss';

interface Props {
    progress?: number;
}

const ProgressBar: React.FC<Props> = ({progress = 0}) => {
    return <div className='progressBar'>
        <div className="progress" style={{'width': `${progress}%`}}/>
    </div>
}

export default ProgressBar;
