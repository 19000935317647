import React, {useEffect, useState} from "react";
import OrdersTable from "./OrdersTable";
import OrderView from "./OrderView";
import './index.scss';
import {getOpenOrders, setOrderStatusToChecking} from "../../../api/firebase";

interface Props {}

const Administration: React.FC<Props> = () => {
    const [orders, setOrders] = useState([] as any[]);
    const [selectedOrder, setSelectedOrder] = useState(null as any);

    useEffect(() => {
        if(orders?.length == 0) getOrdersData();
    }, []);

    const getOrdersData = async () => {
        try {
            const result = await getOpenOrders();
            if(result) setOrders(result);
        } catch (e) {
            console.log(e);
        }
    }

    const selectOrderToEdit = async (order: any) => {
        await setOrderStatusToChecking(order.id, '1stallways@gmail.com');
        setSelectedOrder(order);
    }

    return <>
        {selectedOrder ? <OrderView order={selectedOrder} setSelectedOrder={setSelectedOrder} /> : <OrdersTable orders={orders} selectOrder={selectOrderToEdit} />}
    </>
}

export default Administration;