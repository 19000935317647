import React, {useEffect, useState} from 'react';
import {Route, Switch, useLocation} from 'react-router';
import {Header, WithAuthorization} from './common';
import DeviceSelection from './DeviceSelection';
import PerfectScroll from 'react-perfect-scrollbar';
import DeviceInfoForm from './DeviceInfoForm';
import OrderSummary from './OrderSummary';
import NotFound from './NotFound';
import Shipping from './Shipping';
import Payment from './Payment';
import Resource from './Resource';
import ThankYou from "./Thankyou";

import 'react-perfect-scrollbar/dist/css/styles.css';
import './App.scss';
import {ROUTES} from "../constants";
import Login from "./Login";
import {AuthUserContext} from "../session";
import {onAuthChange} from "../api/firebase";

const App = () => {
    const {pathname} = useLocation();
    const [authUser, setAuthUser] = useState(null as any);
    const isThankYou = pathname === ROUTES.THANKYOU;

    useEffect(() => {
        const listener = onAuthChange(
            (user: any) => {
                user ? setAuthUser(user) : setAuthUser(null);
            });
        return () => listener();

    }, [])

    return (
        <AuthUserContext.Provider
            value={authUser}
        >
            <>
                {!isThankYou && <Header authUser={authUser}/>}

                <div className={`${!isThankYou ? 'appScrollContainer' : ''}`}>
                    <PerfectScroll>
                        <Switch>
                            <Route exact path={[ROUTES.ROOT, ROUTES.HOME]}
                                   component={() => <DeviceSelection/>}
                            />
                            <Route path={ROUTES.INFO}
                                   component={() => <DeviceInfoForm/>}
                            />
                            <Route path={ROUTES.SUMMARY}
                                   component={() => <OrderSummary/>}
                            />
                            <Route path={ROUTES.SHIPPING}
                                   component={() => <Shipping/>}
                            />
                            <Route path={ROUTES.PAYMENT}
                                   component={() => <Payment/>}
                            />
                            <Route path={ROUTES.THANKYOU}
                                   component={() => <ThankYou/>}
                            />
                            <Route path={ROUTES.RESOURCE}
                                   component={() => <WithAuthorization><Resource/></WithAuthorization>}
                            />
                            <Route path={ROUTES.LOGIN} component={() => <Login/>}/>
                            <Route path="*" component={NotFound}/>
                        </Switch>
                    </PerfectScroll>
                </div>
            </>
        </AuthUserContext.Provider>
    );
}

export default App;
