import React, { useState } from 'react';
import { Button, Icon } from '../index';
import './index.scss';
import Models from '../../../types/models';
import { ConditionValue } from '../../../types/enums';

interface Props {
    index: number;
    option: any;
    isCurrent: boolean;
    isSelected: boolean;
    onSubmit: (condition: Models.Conditions) => void;
    currentCondition: Models.Conditions;
    onChange: () => void;
}

const ConditionOption: React.FC<Props> = ({index, option, isCurrent, onSubmit, currentCondition, onChange}) => {
    const {title, subtitle, descriptionElement, descriptionCallbackRequired, descriptionCallBackOption} = option;
    const [generalValue, setGeneralValue] = useState(ConditionValue.Unset);

    const submitValue = (value: ConditionValue) => {
        // if there is no callback in description or callback is required on different value, submit an answer
        !descriptionCallbackRequired || (descriptionCallbackRequired && value !== descriptionCallBackOption)
            ? onSubmit({title, value: value})
            : setGeneralValue(value); // if callback is required for the selected value, set general value
    }

    const submitValueWithExplanation = (explanation: string) => {
        onSubmit({title, value: generalValue, valueExplanation: explanation})
    }

    return <div className={`conditionOption ${isCurrent ? 'current' : ''} ${currentCondition ? 'selected' : ''}`}>
        <div className='conditionOptionHeader'>
            <p>{index + 1}. {title}</p>

            {subtitle && <p className={'conditionOptionSubtitle tablet-big-invisible'}>{subtitle}</p>}

            <div className={`joinedButtons flex-row-center`}>
                {
                    currentCondition
                        ? <p className='joinedButtonsResult'>{currentCondition.value === ConditionValue.Yes ? 'Yes' : 'No'}</p>
                        : <div className="joinedButtonsSelect">
                            <Button className={`short ${generalValue === ConditionValue.Yes ? 'highlighted' : ''}`} click={() => submitValue(ConditionValue.Yes)} label={'Yes'} />
                            <Button className={`short ${generalValue === ConditionValue.No ? 'highlighted' : ''}`} click={() => submitValue(ConditionValue.No)} label={'No'} />
                        </div>
                }

                {
                    currentCondition && <Button
                        className={'link flex-row-center joinedButtonsEdit'}
                        click={() => {
                            setGeneralValue(ConditionValue.Unset);
                            onChange();
                        }}
                    >
                        <Icon type={'edit'}/> <span>Change</span>
                    </Button>
                }
            </div>
        </div>

        {
            (!!descriptionElement && !!subtitle) && <div className="conditionOptionDescription">
                {subtitle && <p className={'conditionOptionSubtitle tablet-big-visible'}>{subtitle}</p>}

                {
                    descriptionElement && <div className="descriptionElement">
                        {/*The 1st element is a selected string, that describes current condition: light/hard scratches*/}
                        {/*The 2nd parameter is a callback, the 3rd is the restriction for the element to be clickable (default: true)*/}
                        {descriptionElement(currentCondition?.valueExplanation, submitValueWithExplanation, generalValue === descriptionCallBackOption)}
                    </div>
                }
            </div>
        }
    </div>
};

export default ConditionOption;
