import React from 'react';
import {ReactComponent as InfoIcon} from '../../../assets/icons/info.svg';
import {ReactComponent as EditIcon} from '../../../assets/icons/edit.svg';
import {ReactComponent as LockIcon} from '../../../assets/icons/lock.svg';
import {ReactComponent as MoneyIcon} from '../../../assets/icons/money.svg';
import {ReactComponent as ArrowIcon} from '../../../assets/icons/arrow.svg';
import {ReactComponent as LeftArrowIcon} from '../../../assets/icons/left-arrow.svg';
import './index.scss';

interface Props {
    type: string;
    size?: string;
    rotate?: number;
}

interface IconTypes {
    [key: string]: any
}

const Icon: React.FC<Props> = ({type, size, rotate= 0}) => {
    const DEFAULT_ICON = 'info';
    const types: IconTypes = {
        'info': <InfoIcon />,
        'edit': <EditIcon />,
        'lock': <LockIcon />,
        'money': <MoneyIcon />,
        'arrow': <ArrowIcon />,
        'left-arrow': <LeftArrowIcon />
    }

    return <div className={`icon ${size === 'small' && 'small'}`} style={{'transform': `rotate(${rotate}deg)`}}>
        {types[type] || types[DEFAULT_ICON]}
    </div>
};

export default Icon;
