const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const getFormatedDate = (date: any) => {
    const numDate = parseFloat(date);
    if(numDate !== numDate) return 'No date';
    const d = new Date(numDate);
    const getTime = (time:number) => time > 9 ? time : '0'+time;
    return `${months[d.getUTCMonth()]} ${d.getUTCDate()}, ${getTime(d.getUTCHours())}:${getTime(d.getUTCMinutes())}`;
}

export {
    getFormatedDate,
}