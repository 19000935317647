import React from 'react';
import './index.scss';
import RadioButton from '../RadioButton';

interface Props {
    isSelected: boolean,
    onSelect: () => void,
    optionData: any,
}

const WideSelectOption:React.FC<Props> = ({isSelected, onSelect, optionData}) => {
    const {title, label, titleHighlight, options} = optionData;

    return <div
        className={`expandableSelect ${isSelected ?  'active' : ''} ${ isSelected && options ? 'expanded' : ''}`}
        onClick={onSelect}
    >
        <header>
            <RadioButton isSelected={isSelected}/>
            <div className={'titles'}>
                <p>{title} {titleHighlight && <span className="color-highlighted">{titleHighlight}</span>}</p>
                <p className={'color-light'}>{label}</p>
            </div>
        </header>
    </div>
};

export default WideSelectOption;
