const ROUTES = {
    ROOT: '/',
    HOME: '/home',
    INFO: '/info',
    SUMMARY: '/summary',
    SHIPPING: '/shipping',
    PAYMENT: '/payment',
    THANKYOU: '/thank-you',
    RESOURCE: '/resource',
    LOGIN: '/login'
}

export default ROUTES;