import React from 'react';

import './index.scss';

interface Props {
    name: string;
    value: string | number;
    label: string;
    placeholder: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    type?: string;
    narrow?: boolean;
    required?: boolean;
    error?: string;
    info?: string;
    className?: string;
    disabled?: boolean;
}

const TextInput:React.FC<Props> = ({name, value, label, placeholder, onChange, type, required = false, error, info, className, disabled}) => {
    return <div className={`inputContainer ${error ? 'error' : ''} ${disabled ? 'disabled' : ''} ${className ? className : ''}`}>
        <label htmlFor={name}>
            {label}&nbsp;
            {required && <span>*</span>}
        </label>
        <input
            type={type || 'text'}
            value={value}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            required={required}
        />
        {/* The space in the error label is a fallback option, so the form inputs won't jump on text update */}
        <p className={!error && info ? 'info' : ''}>{error ? error : info ? info : '&nbsp;'}</p>
    </div>
};

export default TextInput;
