import React  from 'react';
import Tip from '../common/Tip';
import TextInput from '../common/TextInput';
import { Switch, WideSelectOption } from '../common';
import Models from '../../types/models';

interface Props {
    formData: Models.User;
    errors: Models.FormErrors;
    updateForm: (event: React.FormEvent<HTMLInputElement>) => void;
    isInsuranceNecessary: boolean;
    isShippingKitNecessary: boolean;
    setInsureDevice: (value: boolean) => void;
    setIsShippingKitNecessary: (value: boolean) => void;
}

const ShippingForm:React.FC<Props> = ({formData, errors, updateForm, setInsureDevice, isInsuranceNecessary, setIsShippingKitNecessary, isShippingKitNecessary}) => {
    const SHIPPING_OPTIONS = [
        {
            title: 'Use your own box and ship by Mar 25',
            label: 'Use your own packaging materials'
        },
        {
            title: 'Send me a box, I will ship by Mar 29',
            titleHighlight: '(- $10)',
            label: 'We will send you shipping kit'
        }
    ];

    return <div className='leftSideContainer'>
        <h3>Shipping information</h3>
        <Tip label={'Your personal data will be used to process your order'} />

        <div className="formContainer">
            <TextInput
                name={'email'}
                value={formData['email'] || ''}
                label={'Email address'}
                placeholder={'Your email'}
                required={true}
                onChange={updateForm}
                error={errors['email']}
                type={'email'}
            />

            <div className="inputGroup">
                <TextInput
                    name={'name'}
                    value={formData['name'] || ''}
                    label={'First name'}
                    placeholder={'Your name'}
                    required={true}
                    onChange={updateForm}
                    error={errors['name']}
                />
                <TextInput
                    name={'surname'}
                    value={formData['surname'] || ''}
                    label={'Your surname'}
                    placeholder={'Your surname'}
                    required={true}
                    onChange={updateForm}
                    error={errors['surname']}
                />
            </div>

            <div className="inputGroup">
                <TextInput
                    name={'address'}
                    value={formData['address'] || ''}
                    label={'Address'}
                    placeholder={'Full address (street, apt./suite/other)'}
                    required={true}
                    onChange={updateForm}
                    error={errors['address']}
                />

                <TextInput
                    name={'zipCode'}
                    value={formData['zipCode'] || ''}
                    label={'Zip code'}
                    placeholder={'Enter code'}
                    required={true}
                    onChange={updateForm}
                    error={errors['zipCode']}
                />
            </div>

            <div className="inputGroup">
                <TextInput
                    name={'city'}
                    value={formData['city'] || ''}
                    label={'City'}
                    placeholder={'City'}
                    required={true}
                    onChange={updateForm}
                    error={errors['city']}
                />
                <TextInput
                    name={'state'}
                    value={formData['state'] || ''}
                    label={'State'}
                    placeholder={'Your state'}
                    required={true}
                    onChange={updateForm}
                    error={errors['state']}
                />
            </div>

            <div className="inputGroup">
                <TextInput
                    name={'country'}
                    value={formData['country'] || ''}
                    label={'Country'}
                    placeholder={'Country'}
                    required={true}
                    onChange={() => {}}
                    error={errors['country']}
                    info={'We currently only support USA'}
                    disabled
                />
                <TextInput
                    name={'phone'}
                    value={formData['phone'] || ''}
                    label={'Phone number (optional)'}
                    placeholder={'Phone number'}
                    required={true}
                    onChange={updateForm}
                    type={'tel'}
                    error={errors['phone']}
                    info={'We’ll only call you if there is any issue with order'}
                />
            </div>
        </div>

        <div className="insuranceCheck">
            <Switch isSelected={isInsuranceNecessary} toggleCheck={() => setInsureDevice(!isInsuranceNecessary)} />
            <label htmlFor='insurance'>Insure my device <span className='color-highlight'>(- $5)</span></label>
        </div>

        <div className="shippingPreference">
            <h4>Choose Preferred:</h4>
            {SHIPPING_OPTIONS.map((el, i) => <WideSelectOption
                key={i}
                isSelected={(i === 0 && !isShippingKitNecessary) || (i === 1 && isShippingKitNecessary)}
                onSelect={() => setIsShippingKitNecessary(i !== 0 )}
                optionData={el}
            />)}
        </div>
    </div>
}

export default ShippingForm;
