import ROUTES from './routes';

const HEADER_ROUTES_ORDER = [ROUTES.INFO, ROUTES.SUMMARY, ROUTES.SHIPPING, ROUTES.PAYMENT];

const NO_BREADCRUMBS_ROUTES = [ROUTES.ROOT, ROUTES.HOME, ROUTES.THANKYOU, ROUTES.RESOURCE, ROUTES.LOGIN];
const NO_MOBILE_DEVICE_PREVIEW = [ROUTES.ROOT, ROUTES.LOGIN];

export {
    ROUTES,
    HEADER_ROUTES_ORDER,

    NO_BREADCRUMBS_ROUTES,
    NO_MOBILE_DEVICE_PREVIEW
}