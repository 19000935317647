import React, { useState } from 'react';
import ConditionalImage from '../ConditionalImage';
import './index.scss';

interface Props {
    imageUrls: string[];
    titles?: string[];
    onClick?: (data: any) => void;
    isClickable?: boolean;
    selectedDescription?: string;
}

const ConditionalImageSelect: React.FC<Props> = ({imageUrls, titles, onClick, isClickable= true, selectedDescription}) => {
    const [selectedValue, setSelectedValue] = useState();

    const submitValue = (value: string | number) => {
        setSelectedValue(value);
        onClick && onClick(value);
    }

    return <div className='conditionalImagesContainer'>
        {imageUrls.map((el, i) => (
            <ConditionalImage
                key={i}
                isClickable={isClickable}
                imageUrl={el}
                title={titles ? titles[i] : ''}
                isSelected={titles ? selectedDescription === titles[i] : selectedValue === i}
                onClick={onClick ? () => submitValue(titles ? titles[i] : i) : undefined}
            />
        ))}
    </div>
}

export default ConditionalImageSelect;
