import React from 'react';
import './index.scss';

interface Props {
    className?: string;
    label?: string;
    click: () => void;
}

const Button: React.FC<Props> = ({label, click, className, children}) => {
    return <button className={`${className ? className : ''}`} onClick={click}>
        {label && label}
        {children && children}
    </button>
}

export default Button;
