import React from 'react';
import {ReactComponent as PayPalLogo} from "../../../assets/icons/paypal.svg";
import './index.scss';

const PaypalCard = () => {
    return <div className="paypalCard">
        <PayPalLogo />
        <p className="label">$0.3 + 2.9% service fee</p>
    </div>;
}

export default PaypalCard;