import React from "react";
import {OrderStatus} from "../../../types/enums";
import {getFormatedDate} from '../../../untilities';

interface Props {
    orders: any[],
    selectOrder: (order: any) => void
}

const OrdersTable: React.FC<Props> = ({orders, selectOrder}) => {
    return <>
        <h1>Orders</h1>
        <div className="tableContainer">
            <div className='table'>
                <div className='headers'>
                    <span>ID</span>
                    <span>Date</span>
                    <span>User Email</span>
                    <span>User Name</span>
                    <span>Status</span>
                </div>
                <div className='entries'>
                    {orders?.length > 0 && orders.map(order => <div
                        onClick={() => selectOrder(order)}
                        className='row' key={order.id}
                    >
                        <span>{order.id}</span>
                        <span>{`${getFormatedDate(order.confirmationDate)}`}</span>
                        <span>{order.shippingDetails.user.name}</span>
                        <span>{order.shippingDetails.user.email}</span>
                        <span>{OrderStatus[order.status]}</span>
                    </div>)}
                </div>
            </div>
        </div>
    </>;
}

export default OrdersTable;