import React from 'react';
import { ReactComponent as Logo } from '../../../assets/logo.svg';
import './index.scss';
import Breadcrumbs from '../Breadcrumbs';
import ProgressBar from '../ProgressBar';
import { useLocation } from 'react-router';
import MobileViewDevicePreview from '../MobileViewDevicePreview';
import { useSelector } from 'react-redux';
import Models from '../../../types/models';
import {ROUTES, HEADER_ROUTES_ORDER, NO_BREADCRUMBS_ROUTES, NO_MOBILE_DEVICE_PREVIEW} from "../../../constants";

interface Props {
    authUser: any,
}

const Header: React.FC<Props> = ({authUser}) => {
    const {pathname} = useLocation();

    const pathLocation = HEADER_ROUTES_ORDER.indexOf(pathname) + 1;

    const isRootPath = pathname === ROUTES.ROOT || pathname === ROUTES.HOME;
    const isBreadCrumbsShown = NO_BREADCRUMBS_ROUTES.indexOf(pathname) < 0 && authUser;

    const {currentDevice: {description}} = useSelector((state: Models.AppState) => state.currentOrder)

    return <>
        <div className='headerContainer'>
            <div className={`logoContainer ${isRootPath ? 'displaySign' : ''}`}>
                <Logo/>
            </div>
            {isBreadCrumbsShown && <Breadcrumbs crumbs={HEADER_ROUTES_ORDER}/>}
            <ProgressBar progress={ pathLocation > 0 ? pathLocation < HEADER_ROUTES_ORDER.length ? (100/HEADER_ROUTES_ORDER.length) * pathLocation : 90 : 0}/>
        </div>
        {NO_MOBILE_DEVICE_PREVIEW.indexOf(pathname) < 0 && description.name && <MobileViewDevicePreview imageUrl={description.imageUrl} name={description.name} />}
    </>
}

export default Header;

