import React from 'react';
import './index.scss';
import Administration from "./Administration";

interface Props {}

const Resource: React.FC<Props> = () => {
    return <main className="contentArea resource">
        <Administration />
    </main>;
}

export default Resource;