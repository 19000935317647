import React from 'react';

import './index.scss';
import {Divider, Icon} from '../index';
import {removeDeviceFromOrderAction} from "../../../redux/actions/orderCreation";

import Models from "../../../types/models";
import {useDispatch} from "react-redux";
import {
    editCurrentDeviceCarrierInfoAction,
    editCurrentDeviceConditionsInfoAction,
    editCurrentDeviceStorageInfoAction, setInfoFormStepAction
} from "../../../redux/actions/formFilling";
import history from '../../../helpers/history';
import {Steps} from "../../DeviceInfoForm";

interface Props {
    itemData: Models.Device;
    isExpanded: boolean;
    click: () => void;
}

const SingleDeviceItemView: React.FC<Props> = ({itemData, isExpanded = false, click}) => {
    const {description, carrier, storage, id} = itemData;
    const {name, imageUrl, price} = description;
    const dispatch = useDispatch();
    const EDIT_OPTIONS: {[key: string]: any} = {
        'Storage': () => {
            dispatch(editCurrentDeviceStorageInfoAction(itemData));
            dispatch(setInfoFormStepAction(Steps.Storage))
        },
        'Carrier': () => {
            dispatch(editCurrentDeviceCarrierInfoAction(itemData));
            dispatch(setInfoFormStepAction(Steps.Carrier))
        },
        'Conditions': () => {
            dispatch(editCurrentDeviceConditionsInfoAction(itemData));
            dispatch(setInfoFormStepAction(Steps.Conditions))
        }
    }
    const edit = (type: string) => {
        EDIT_OPTIONS[type]();
        dispatch(removeDeviceFromOrderAction(id));
        history.push('/info');
    }

    return <div className={`itemContainer ${isExpanded ? 'expanded' : ''}`} onClick={click}>
        <header>
            <div className="itemDescription">
                <div className="itemImageContainer">
                    <img
                        src={require(`../../../assets/phones/${imageUrl}`)}
                        alt={name}
                    />
                </div>

                <div className="itemInfo">
                    <h4>{name}</h4>
                    <p className='itemPriceName'>
                        <span className='itemPrice'>${price}</span>
                    </p>
                </div>
            </div>

            <Icon type={'arrow'}/>
        </header>

        <div className="infoDescriptionDetails">
            <a onClick={() => dispatch(removeDeviceFromOrderAction(id))}>Remove</a>
            <ul>
                <li onClick={() => edit('Storage')}>
                    <span>Storage</span>
                    <span>{storage}</span>
                    <Icon type='edit'/>
                </li>
                <li onClick={() => edit('Carrier')}>
                    <span>Carrier</span>
                    <span>{carrier}</span>
                    <Icon type='edit'/>
                </li>
                <li onClick={() => edit('Conditions')}>
                    <span>Condition</span>
                    <span>{'Conditions'}</span>
                    <Icon type='edit'/>
                </li>
            </ul>
        </div>
    </div>
}

export default SingleDeviceItemView;
