import { ORDER_ACTIONS } from './types';
import Models from '../../types/models';

const addCurrentDeviceToOrderAction = () => {
    return {type: ORDER_ACTIONS.ADD_DEVICE}
}

const removeDeviceFromOrderAction = (id: string) => {
    return {type: ORDER_ACTIONS.REMOVE_DEVICE, payload: id}
}

const addUserDetailsToShipping = (user: Models.User) => {
    return {type: ORDER_ACTIONS.ADD_SHIPPING_USER, payload: user}
}

const addInsuranceToShipping = (isInsuranceNecessary: boolean) => {
    return {type: ORDER_ACTIONS.ADD_SHIPPING_INSURANCE, payload: isInsuranceNecessary}
}

const addShippingKitToShipping = (isShippingKitNecessary: boolean) => {
    return {type: ORDER_ACTIONS.ADD_SHIPPING_KIT, payload: isShippingKitNecessary}
}

const addPaymentSpeedOrderAction = (value: boolean) => {
    return {type: ORDER_ACTIONS.ADD_PAYMENT_SPEED, payload: value}
}

const addPaymentOrderAction = (paymentDetails: Models.PaymentDetails) => {
    return {type: ORDER_ACTIONS.ADD_PAYMENT, payload: paymentDetails}
}

const finalizeOrder = () => {
    return {type: ORDER_ACTIONS.FINALIZE_ORDER}
}

export {
    addCurrentDeviceToOrderAction,
    addUserDetailsToShipping,
    addInsuranceToShipping,
    addShippingKitToShipping,
    addPaymentOrderAction,
    addPaymentSpeedOrderAction,
    removeDeviceFromOrderAction,
    finalizeOrder
}
