import React from 'react';
import './index.scss';
import {ROUTES} from "../../constants";
import {Icon} from "../common";
import MobilePhoneIllustration from "../../assets/thankyou/mobile_phone_illustration.svg";
import SquareBoxIllustration from "../../assets/thankyou/square_box_illustration.svg";
import LongBox from "../../assets/thankyou/long_box.svg";
import SmallMobilePhone from "../../assets/thankyou/small_mobile_phone.svg";
import SquareBox from "../../assets/thankyou/square_box.svg";
import ThinBox from "../../assets/thankyou/thin_box.svg";
import {ReactComponent as Logo} from "../../assets/logo.svg";

const ThankYou = () => {
    return <div className='thankYouContainer'>
        <header>
            <a href={ROUTES.ROOT} className='backImageUrl'>
                <Icon type={'left-arrow'} />
                <span>Back to homepage</span>
            </a>
        </header>

        <div className="pageContent">
            <div className="upperText">
                <div className="logo-container">
                    <Logo />
                </div>

                <h2>Thanks for using Swaptecs</h2>
                <p className='color-light confirmation-text'>
                    We will send a confirmation email with tracking info, checklist <br/>
                    and details to <span className='color-highlighted'>johnsmith@mail.com</span>
                </p>
                <p className='color-light'>
                    Your order number is <span className='color-dark'>234633710</span>
                </p>

                <img className="illustration-item long-box-image" src={LongBox} alt="Long Box image"/>
                <img className="illustration-item upper-left-phone" src={SmallMobilePhone} alt="Small Mobile Phone image"/>
                <img className="illustration-item bottom-right-phone" src={SmallMobilePhone} alt="Small Mobile Phone image"/>
                <img className="illustration-item left-square-box" src={SquareBox} alt="Square Box image"/>
                <img className="illustration-item middle-box" src={SquareBox} alt="Square Box image"/>
                <img className="illustration-item right-upper-box" src={SquareBox} alt="Square Box image"/>
                <img className="illustration-item bottom-left-box" src={ThinBox} alt="Thin box image"/>
                <img className="illustration-item top-right-box" src={ThinBox} alt="Thin box image"/>
            </div>

            <div className="instruction-section">
                <h2>Shipping instructions</h2>

                <div className="instruction-item">
                    <img src={MobilePhoneIllustration} className="first-image" alt="Device preparation"/>

                    <div className="instruction-item-text">
                        <h3>Device preparation</h3>
                        <ul>
                            <li>Don't forget to backup your photos, files or other personal information. <a
                                href="">Read here</a></li>
                            <li>Turn off “Find My iPhone” and remove your iCloud account. <a href="">Watch video</a></li>
                            <li>If your device has cellular service, deactivate your carrier settings. <a href="">Read here</a></li>
                            <li>Reset phone to factory settings and remove your SIM card if there </li>
                            <li>Please charge your device at least partially, so we can process faster</li>
                        </ul>
                    </div>
                </div>


                <div className="instruction-item">
                    <img src={SquareBoxIllustration} className="second-image" alt="Packaging & shipping"/>

                    <div className="instruction-item-text">
                        <h3>Packaging & shipping</h3>
                        <ul>
                            <li>Pack your item securely in the box with plenty of packing materials</li>
                            <li>Tape a <a href="">shipping label</a> to the box and be sure barcode is flat and legible</li>
                            <li>Don't have a printer? Show it on the phone to postal worker.</li>
                            <li>Ship your item by <b>March 27th</b> to qualify for the <a href="">14-day price guarantee</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default ThankYou;