import React, {useState} from 'react';
import TextInput from "../common/TextInput";
import history from '../../helpers/history';
import {ROUTES} from "../../constants";
import app, {signInWithEmailAndPassword} from "../../api/firebase";

interface Props {
}

const Login: React.FC<Props> = () => {
    const [form, updateForm] = useState({login: '', password: ''});

    const authorize = (user: string, password: string) => {
        app.auth().setPersistence(app.auth.Auth.Persistence.SESSION)
            .then(() => {
                return signInWithEmailAndPassword(user, password).then((user: any) => {
                    if(user) {
                        history.push(ROUTES.ROOT);
                        return user
                    }
                    return null
                })
            })
            .catch((e: any) => {
                console.log(e);
            })
    }

    return <div className='form'>
        <TextInput
            type="text"
            placeholder='login'
            name={'login'}
            value={form.login}
            label='Login'
            onChange={(e) => updateForm({...form, login: e.currentTarget.value})}
        />
        <TextInput
            type="password"
            placeholder='password'
            name={'password'}
            value={form.password}
            label='Password'
            onChange={(e) => updateForm({...form, password: e.currentTarget.value})}
        />
        <button onClick={() => authorize(form.login, form.password)}>Submit</button>
    </div>
}

export default Login;