import React, {useState} from "react";
import {getFormatedDate} from "../../../untilities";
import {OrderStatus} from "../../../types/enums";
import Models from "../../../types/models";
import OrderViewDevice from "./OrderViewDevice";
import {Button} from "../../common";
import TextInput from "../../common/TextInput";
import {closeOrder} from "../../../api/firebase";

interface Props {
    order: any,
    setSelectedOrder: (order: any) => void
}

const OrderView: React.FC<Props> = ({order, setSelectedOrder}) => {
    const {shippingDetails: {user}, devices} = order;
    const [orderPrice, setOrderPrice] = useState();

    const closeCurrentOrder = async () => {
        await closeOrder(order.id, order.editor);
    }

    return <div className='orderView'>
        <button onClick={() => {setSelectedOrder(null)}}>Back to the list</button>

        <div className="orderInfo">
            <h1>Order ID: {order.id}</h1>

            <div className={`status ${OrderStatus[order.status]}`}>{OrderStatus[order.status]}</div>
        </div>

        <div className="orderSubmissionInfo">
            <h2>Current Order Price: {devices.reduce((p: number, c: Models.Device) => {return p + c.description.price}, 0)}</h2>
            <p>Submitted on: {getFormatedDate(order.confirmationDate)}</p>
        </div>

        <section className="orderData">
            <div className="orderUserData">
                <h2>User Details:</h2>

                <ul>
                    {Object.keys(user).map((key) => <li key={key}>
                        <b>{key}</b>: <span>{user[key]}</span>
                    </li>)}
                </ul>
            </div>

            <div className="orderDevicesData">
                <h2>Devices:</h2>

                {devices.map((device: Models.Device) => <OrderViewDevice device={device} />)}
            </div>
        </section>

        <section>
            <h2>Order Controls: </h2>
            <div className="orderControls">
                <TextInput
                    type={'number'}
                    label={'Propose Different Price'}
                    placeholder={'New Price'}
                    name={'newOrderPrice'}
                    value={orderPrice}
                    onChange={(e) => setOrderPrice(parseFloat(e.currentTarget.value))}
                />
                <Button click={() => closeCurrentOrder()}>Close Order</Button>
            </div>
        </section>
    </div>
}

export default OrderView;