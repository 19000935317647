import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';


const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const initialStore = JSON.parse(localStorage.getItem('appState') || '{}');

const store = createStore(
    rootReducer,
    initialStore,
    composeEnhancers(applyMiddleware(thunk)),
);

store.subscribe(()=>{
    localStorage.setItem('appState', JSON.stringify(store.getState()))
});

export default store;
