import React, {useContext, useState} from 'react';
import {Redirect} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {Icon, InfoBox, Button, Divider} from '../common';
import Models from '../../types/models';
import PaymentInfo from './PaymentInfo';
import {
    finalizeOrder,
    addPaymentSpeedOrderAction
} from '../../redux/actions/orderCreation';
import {submitOrder} from "../../api/firebase";

import './index.scss';

const Payment: React.FC = () => {
    const currentOrder = useSelector((state: Models.AppState) => state.currentOrder)
    const {devices, currentDevice, shippingDetails: {isInsuranceNecessary, isShippingKitNecessary}, paymentDetails: {isFastReviewSelected}} = currentOrder;

    const dispatch = useDispatch();

    const [payPalEmail, setPaypalEmail] = useState('');
    const [paymentErrors, setErrors] = useState([] as Array<string | null>);
    const [payPalEmailConfirmation, setPaypalEmailConfirm] = useState('');
    const EMAIL_REG_EXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const EMAILS_SHOULD_BE_EQUAL = 'Emails should be the same';
    const EMAIL_HAS_TO_BE_FULL = 'Email has to be full: email@domain.com';

    if(devices.length === 0 || !!currentDevice.id || currentDevice.editMode) return <Redirect to={'/info'} />;

    const isFormValid = () => {
        setErrors([]);
        if (payPalEmail !== payPalEmailConfirmation) {
            setErrors([null, EMAILS_SHOULD_BE_EQUAL]);
            return false;
        } else if (!EMAIL_REG_EXP.test(payPalEmail) || payPalEmail.length === 0) {
            setErrors([EMAIL_HAS_TO_BE_FULL, null]);
            return false;
        } else if (!EMAIL_REG_EXP.test(payPalEmailConfirmation) || payPalEmailConfirmation.length === 0) {
            setErrors([null, EMAIL_HAS_TO_BE_FULL]);
            return false;
        }
        return paymentErrors.length === 0;
    }

    const validateFormAndSubmitOrder = () => {
        if (isFormValid()) {
            const paymentDetails = {
                payPalEmail,
                payPalEmailConfirmation,
                isFastReviewSelected
            };

            submitOrder({...currentOrder, paymentDetails})
                .then(() => {
                    dispatch(finalizeOrder());
                })
        }
    }

    return <main className="contentArea">
        <div className="paymentContainer">
            <section className='orderSummaryForm'>
                <PaymentInfo
                    paymentErrors={paymentErrors}
                    paypalEmail={payPalEmail}
                    setPaypalEmail={setPaypalEmail}
                    paypalEmailConfirm={payPalEmailConfirmation}
                    setPaypalEmailConfirm={setPaypalEmailConfirm}
                    isFastInspection={isFastReviewSelected}
                    setInspectionFast={value => dispatch(addPaymentSpeedOrderAction(value))}
                />
            </section>

            <section className='orderSummary'>
                <div className="orderSummaryDetails">
                    <h4>Order summary</h4>

                    <div className="itemsPrices">
                        {
                            devices.map(({description: {name, price}}, index) => (
                                <div
                                    className="itemNameAndPrice"
                                    key={index}
                                >
                                    <span>{name}</span>
                                    <span>${price}</span>
                                </div>
                            ))
                        }
                    </div>

                    <Divider/>

                    {(isInsuranceNecessary || isShippingKitNecessary || isFastReviewSelected) &&
                    <div className="optionalItems">
                        {
                            isInsuranceNecessary && <p className='optionalItem'>
                                <span>Device insurance</span>
                                <span>- $5</span>
                            </p>
                        }
                        {
                            isShippingKitNecessary && <p className='optionalItem'>
                                <span>Send a shipping kit</span>
                                <span>- $10</span>
                            </p>
                        }
                        {
                            isFastReviewSelected && <p className='optionalItem'>
                                <span>Get paid faster</span>
                                <span>- $10</span>
                            </p>
                        }
                    </div>
                    }

                    <Divider/>

                    <div className="totalOrderPrice">
                        <span>Total payout</span>
                        <span className='totalPrice'>${devices.reduce((p, c) => {
                            return p + c.description.price
                        }, 0) - (isInsuranceNecessary ? 5 : 0) - (isShippingKitNecessary ? 10 : 0) - (isFastReviewSelected ? 10 : 0)}</span>
                    </div>

                    <InfoBox text={'Please note, we do not pay for devices that have been reported lost or stolen.'}/>

                    <div className="buttonsContainer">
                        <Button label={'Confirm order'} click={() => validateFormAndSubmitOrder()} className={'wide primary'}/>
                    </div>

                    <div className='orderSummaryParams'>
                        <div className='paramItem'>
                            <Icon type={'money'}/>
                            <span>14 day offer guarantee</span>
                        </div>
                        <div className='paramItem'>
                            <Icon type={'lock'}/>
                            <span>Secure payment</span>
                        </div>
                    </div>
                </div>

                <p className='orderSummaryInfoText'>
                    We reserve the right to reject the device, or make an adjusted offer, if the device does not confirm to
                    the description you provided or for other reasons written in our terms and conditions.
                </p>
            </section>
        </div>
    </main>
};

export default Payment;
