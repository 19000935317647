import React from 'react';

const NotFound: React.FC = () => {
    return <>
        <h2>Hey, you've probably got here by mistake:</h2>
        <a href="/">Go home</a>
    </>
};

export default NotFound;
