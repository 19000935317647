import {CURRENT_DEVICE_INFO_FILLING_ACTIONS, DEVICE_ACTION_TYPES} from '../actions/types';
import {CURRENT_DEVICE_INITIAL_STATE} from "./orderReducer";
import {v4 as uuid} from "uuid";

const currentDeviceReducer = (state = CURRENT_DEVICE_INITIAL_STATE, action: DEVICE_ACTION_TYPES) => {
    switch (action.type) {
        case CURRENT_DEVICE_INFO_FILLING_ACTIONS.EDIT_DEVICE_STORAGE:
            return Object.assign({}, state, { ...action.payload, editMode: true, storage: CURRENT_DEVICE_INITIAL_STATE.storage})
        case CURRENT_DEVICE_INFO_FILLING_ACTIONS.EDIT_DEVICE_CARRIER:
            return Object.assign({}, state, { ...action.payload, editMode: true, carrier: CURRENT_DEVICE_INITIAL_STATE.carrier})
        case CURRENT_DEVICE_INFO_FILLING_ACTIONS.EDIT_DEVICE_CONDITIONS:
            return Object.assign({}, state, { ...action.payload, editMode: true, conditions: CURRENT_DEVICE_INITIAL_STATE.conditions})
        case CURRENT_DEVICE_INFO_FILLING_ACTIONS.DEVICE_SELECTION:
            return Object.assign({}, state, {description: {...action.payload}, id: uuid()})
        case CURRENT_DEVICE_INFO_FILLING_ACTIONS.SELECT_MEMORY:
            return Object.assign({}, state, {storage: action.payload, editMode: false})
        case CURRENT_DEVICE_INFO_FILLING_ACTIONS.SELECT_CARRIER:
            return Object.assign({}, state, {carrier: action.payload, editMode: false})
        case CURRENT_DEVICE_INFO_FILLING_ACTIONS.UPDATE_CONDITIONS:
            return Object.assign({}, state, {conditions: action.payload, editMode: false})
        case CURRENT_DEVICE_INFO_FILLING_ACTIONS.SET_INFO_FORM_STEP:
            return Object.assign({}, state, {deviceInfoFormStep: action.payload})
        case CURRENT_DEVICE_INFO_FILLING_ACTIONS.CLEAR_INFO:
            return CURRENT_DEVICE_INITIAL_STATE;
        default:
            return state;
    }
}

export default currentDeviceReducer;
