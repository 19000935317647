import React from 'react';
import { ReactComponent as Check } from '../../../assets/icons/check.svg';
import './index.scss';

interface Props {
    isSelected?: boolean;
    className?: string;
}

const RadioButton: React.FC<Props> = (
    {
        isSelected = false,
        className
    }) => <div className='radioButton'>
    <div className={`radioButtonUI ${className ? className : ''} ${isSelected ? 'active' : ''}`}><Check/></div>
</div>;

export default RadioButton;
