import React from 'react';
import './index.scss';

interface propTypes {
    imageUrl: string;
    name: string
}

const MobileViewDevicePreview: React.FC<propTypes> = ({imageUrl, name}) => {
    return <div className='mobileViewDevicePreview'>
        <img src={require(`../../../assets/phones/${imageUrl}`)} alt={name}/>
        <p>{name}</p>
    </div>;
}

export default MobileViewDevicePreview;
