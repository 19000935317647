import Tip from '../../common/Tip';
import React, {useEffect, useState} from 'react';
import ConditionOption from '../../common/ConditionOption';
import Models from '../../../types/models';
import ConditionalImageSelect from './components/ConditionalImageSelect';
import { ConditionValue } from '../../../types/enums';
import VideoPopup from "../../common/VideoPopup";

interface Props {
    onCompleteForm: () => void;
    onUpdateCondition: (value: any) => void;
    conditions: Models.Conditions[];
}

const ConditionSelect:React.FC<Props> = ({onCompleteForm, onUpdateCondition, conditions}) => {
    const addCondition = (newCondition: Models.Conditions) => onUpdateCondition([...conditions, newCondition]);
    const trimConditions = (index: number) => onUpdateCondition([...conditions.slice(0, index)]);
    const [videoPopupShown, setVideoPopupShown] = useState(false);
    const CHECK_YOUR_IPHONE_CONDITION_VIDEO_URL = 'https://www.youtube.com/embed/xK-ukCA5UGs&feature=youtu.be&autoplay=1';

    useEffect(() => {
        if(conditions.length === options.length) onCompleteForm();
    }, [conditions])

    // TODO: getting this form api
    const options = [
        {
            title: 'Does the device power on?',
        },
        {
            title: 'Is your device fully functional?',
            subtitle: 'Fully functional means that all features are in working condition.',
            descriptionElement: () => <>
                <h5>What could be damaged:</h5>

                <ul>
                    <li>Screen doesn’t fully light up or dim</li>
                    <li>Facial recognition and fingerprint sensor</li>
                    <li>Speakers and microphones</li>
                    <li>Physical buttons</li>
                    <li>Camera fails to launch</li>
                    <li>Cellular, Wi-Fi, Bluetooth, GPS</li>
                    <li>Headphone jack and charging port</li>
                    <li>Touchscreen</li>
                </ul>
            </>,
        },
        {
            title: 'Are there scratches anywhere?',
            subtitle: 'Remove any screen/body protectors, put the device under a bright light to check for scratches.',
            descriptionCallbackRequired: true,
            descriptionCallBackOption: ConditionValue.Yes,
            descriptionElement: (selectedDescription?: string, selectDescription?: (value: boolean) => void, isClickable?: boolean) => (
                <ConditionalImageSelect
                    imageUrls={['condition/scratch-1.png', 'condition/scratch-2.png']}
                    titles={['Light scratches', 'Major scratching']}
                    selectedDescription={selectedDescription}
                    onClick={selectDescription}
                    isClickable={isClickable}
                />
            ),
        },
        {
            title: 'Are there cracks anywhere?',
            subtitle: 'This includes signs of wear, minor dents, shattered front or back screen, some scratching and cracks, light body damage.',
            descriptionElement: () => (
                <ConditionalImageSelect
                    imageUrls={['condition/crack-1.png', 'condition/crack-2.png']}
                />
            ),
        }
    ];

    return <>
        <div className="deviceInfoUp">
            <h3>Condition of your phone</h3>
            <Tip
                label={'How to check the condition'}
                isLarge={true}
            >
                <ul>
                    <li>
                        <span className="color-light">1. </span>
                        Remove any screen/body protectors for your device.
                    </li>
                    <li>
                        <span className="color-light">2. </span>
                        Put the device under a light to check for scratches.
                    </li>
                    <li>
                        <span className="color-light">3. </span>
                        Check the device for any bends in the frame,
                        check if there no screen separation.
                    </li>
                    <li>
                        <span className="color-light">4. </span>
                        Check the availability of issues that could be caused by water.
                    </li>
                </ul>

                <a className={'watchVideoUrl'} onClick={() => setVideoPopupShown(true)}>
                    <span>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="9" cy="9" r="9" fill="#ECEAFF"/>
                            <path d="M11.5678 8.17985C12.1408 8.55615 12.1448 9.39154 11.5754 9.77323L8.49948 11.835C7.86045 12.2634 7 11.8077 7 11.0409L7 6.9591C7 6.1964 7.85227 5.73985 8.4919 6.1599L11.5678 8.17985Z" fill="#6254FF"/>
                        </svg>
                    </span>
                    <span>Watch video</span>
                </a>
            </Tip>

            <div className="deviceInfoParameterContainer">
                <div className="column selectGroup">
                    {
                        options.map((option, i) => <ConditionOption
                            key={i}
                            option={option}
                            isCurrent={i === conditions.length}
                            isSelected={i === conditions.length - 1}
                            currentCondition={conditions[i]}
                            index={i}
                            onSubmit={addCondition}
                            onChange={() => trimConditions(i)}
                        />)
                    }
                </div>
            </div>
        </div>

        <VideoPopup
            isVisible={videoPopupShown}
            onClose={() => setVideoPopupShown(false)}
            youtubeVideoUrl={CHECK_YOUR_IPHONE_CONDITION_VIDEO_URL}
        />
    </>
}

export default ConditionSelect;
