import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Icon, InfoBox, Button, Divider } from '../common';
import Models from '../../types/models';
import history from '../../helpers/history';
import ShippingForm from './ShippingForm';
import {
    addInsuranceToShipping,
    addShippingKitToShipping,
    addUserDetailsToShipping
} from '../../redux/actions/orderCreation';
import './index.scss';

const Shipping: React.FC = () => {
    // TODO: refactor validation
    const {devices, currentDevice, shippingDetails: {isInsuranceNecessary, isShippingKitNecessary}} = useSelector((state: Models.AppState) => state.currentOrder)
    const dispatch = useDispatch();
    const EMPTY_MSG = 'Can\'t be empty';
    const INITIAL_USER_SHIPPING_DETAILS_FORM_STATE = {
        email: '',
        name: '',
        surname: '',
        address: '',
        zipCode: '',
        city: '',
        state: '',
        country: 'United States of America',
        phone: '',
    }
    const ErrorLabels: Models.ErrorLabels = {
        email: 'Email has to be full: email@domain.com',
        name: 'Name should contain letters only',
        surname: 'Surname should contain letters only',
        address: 'Address is required',
        zipCode: 'Zip code can be 5 or 9 digits',
        city: 'City can contain only letters',
        state: 'Full name or 2 capital letters',
        phone: 'Phone can be specified as (123)123-1234'
    };
    const RegExps: Models.RegExps = {
        email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        name: /[a-zA-Z ]+/,
        surname: /[a-zA-Z ]+/,
        address: /[a-zA-Z0-9 ]+/,
        zipCode: /^[0-9]{5}(?:-[0-9]{4})?$/,
        city: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
        state: /([A-Za-z]{4,30}|[A-Z]{2})/,
        phone: /((1\s|\B)?\(?[0-9]{3}[-\s)]\s?[0-9]{3}[-\s][0-9]{4}|[0-9]{10})/,
    }
    const [formData, updateFormData] = useState(INITIAL_USER_SHIPPING_DETAILS_FORM_STATE as Models.User);
    const [formErrors, setFormErrors] = useState({} as Models.FormErrors);

    const updateUserShippingDetailsValue = (event: React.FormEvent<HTMLInputElement>) => {
        setFormErrors({});
        updateFormData(Object.assign({}, formData, {[event.currentTarget.name]: event.currentTarget.value}))
    };

    if(devices.length === 0 || !!currentDevice.id || currentDevice.editMode) return <Redirect to={'/info'} />;

    const isFormValid = () => {
        const errors = {} as Models.FormErrors;

        for(let key of Object.keys(formData)) {
            if(formData[key].length === 0) {
                errors[key] = EMPTY_MSG;
            } else {
                if(!RegExps[key] || RegExps[key].test(formData[key])) {
                    delete errors[key]
                } else {
                    errors[key] = ErrorLabels[key];
                }
            }
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const submitShipping = () => {
        if(isFormValid()) {
            dispatch(addUserDetailsToShipping(formData));
            history.push('/payment');
        }
    }

    return <main className="contentArea">
        <div className="shippingContainer">
            <section className='orderSummaryForm'>
                <ShippingForm
                    formData={formData}
                    errors={formErrors}
                    updateForm={updateUserShippingDetailsValue}
                    isInsuranceNecessary={isInsuranceNecessary}
                    isShippingKitNecessary={isShippingKitNecessary}
                    setInsureDevice={(value: boolean) => dispatch(addInsuranceToShipping(value))}
                    setIsShippingKitNecessary={(value: boolean) => dispatch(addShippingKitToShipping(value))}
                />
            </section>

            <section className='orderSummary'>
                <div className="orderSummaryDetails">
                    <h4>Order summary</h4>

                    <div className="itemsPrices">
                        {
                            devices.map(({description: {name, price}}, index) => (
                                <div
                                    className="itemNameAndPrice"
                                    key={index}
                                >
                                    <span>{name}</span>
                                    <span>${price}</span>
                                </div>
                            ))
                        }
                    </div>

                    <Divider />

                    {
                        (isInsuranceNecessary || isShippingKitNecessary) && <div className="optionalItems">
                            {
                                isInsuranceNecessary && <p className='optionalItem'>
                                    <span>Device insurance</span>
                                    <span>- $5</span>
                                </p>
                            }
                            {
                                isShippingKitNecessary && <p className='optionalItem'>
                                    <span>Send a shipping kit</span>
                                    <span>- $10</span>
                                </p>
                            }
                            <Divider/>
                        </div>
                    }

                    <div className="totalOrderPrice">
                        <span>Total payout</span>
                        <span className='totalPrice'>${devices.reduce((p, c) => {return p + c.description.price}, 0) - (isInsuranceNecessary ? 5 : 0) - (isShippingKitNecessary ? 10 : 0)}</span>
                    </div>

                    <InfoBox text={'Please note, we do not pay for devices that have been reported lost or stolen.'} />

                    <div className="buttonsContainer">
                        <Button label={'Continue to payment'} click={() => submitShipping()} className={'wide primary'}/>
                    </div>

                    <div className='orderSummaryParams'>
                        <div className='paramItem'>
                            <Icon type={'money'}/>
                            <span>14 day offer guarantee</span>
                        </div>
                        <div className='paramItem'>
                            <Icon  type={'lock'}/>
                            <span>Secure payment</span>
                        </div>
                    </div>
                </div>

                <p className='orderSummaryInfoText'>
                    We reserve the right to reject the device, or make an adjusted offer, if the device does not confirm to the description you provided or for other reasons written in our terms and conditions.
                </p>
            </section>
        </div>
    </main>
};

export default Shipping;
