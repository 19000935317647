import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InfoBox } from '../common';
import './index.scss';
import Button from '../common/Button';
import StorageSelect from './Steps/StorageSelect';
import CarrierSelect from './Steps/CarrierSelect';
import ConditionSelect from './Steps/ConditionSelect';
import Models from '../../types/models';
import {
    deleteCurrentDeviceInfoAction,
    selectCarrierAction,
    updateConditionAction,
    selectMemoryAction,
    setInfoFormStepAction
} from '../../redux/actions/formFilling';
import { Redirect } from 'react-router';
import history from '../../helpers/history';
import { addCurrentDeviceToOrderAction } from '../../redux/actions/orderCreation';

export enum Steps {
    Storage,
    Carrier,
    Conditions
}

const DeviceInfoForm: React.FC = () => {
    const {currentDevice} = useSelector((state: Models.AppState) => state.currentOrder);
    const {description, deviceInfoFormStep, conditions, editMode} = currentDevice;
    const dispatch = useDispatch();

    // TODO: move declaration
    const memoryOptions = [64, 256, 512];
    const carriers = ['unlocked', 'atnt', 'verizon', 'tmobile', 'sprint', 'other'];

    const selectMemory = (value: number) => {
        dispatch(selectMemoryAction(value));

        !editMode ? nextStep() : completeDeviceInfo();
    };

    const selectCarrier = (value: string) => {
        dispatch(selectCarrierAction(value));

        !editMode ? nextStep() : completeDeviceInfo();
    };

    const selectCondition = (value: string) => {
        dispatch(updateConditionAction(value));
    }

    const completeDeviceInfo = async () => {
        await Promise.all([
            dispatch(addCurrentDeviceToOrderAction()),
            dispatch(deleteCurrentDeviceInfoAction()),
            dispatch(setInfoFormStepAction(Steps.Storage)),
            history.push('/summary')
        ]);
    }

    const STEPS_SEQUENCE = [
        <StorageSelect memoryOptions={memoryOptions} onSelect={selectMemory}/>,
        <CarrierSelect carriersOptions={carriers} onSelect={selectCarrier} />,
        <ConditionSelect onUpdateCondition={selectCondition} onCompleteForm={() => completeDeviceInfo()} conditions={conditions}/>,
    ];

    const nextStep = () => {
        const next = deviceInfoFormStep + 1;
        dispatch(setInfoFormStepAction(next <=  STEPS_SEQUENCE.length - 1 ? next : deviceInfoFormStep));
    }

    const prevStep = () => {
        if(deviceInfoFormStep > 0) {
            dispatch(setInfoFormStepAction(deviceInfoFormStep - 1));
        } else {
            dispatch(deleteCurrentDeviceInfoAction());
        }
    }

    if(!description.name) return <Redirect to={'/'} />

    const {name, imageUrl} = description;

    return <main className="contentArea">
        <div className="deviceInfoFormContainer">
            <div className='deviceInfo'>
                {STEPS_SEQUENCE[deviceInfoFormStep]}

                <Button className='mobile-mt-1 mobile-small-full-width' label={'Previous Step'} click={() => prevStep()}/>
            </div>

            <div className="phonePreview">
                <h4>{name}</h4>
                <img src={require(`../../assets/phones/${imageUrl}`)} alt={name}/>
                <InfoBox text={'In order to give you proper price estimation we need to know actual condition of your device.'} />
                <a href="/" className={'faq-link'}>Have questions? Visit our <span className={'color-highlight'}>FAQs</span></a>
            </div>
        </div>
    </main>
};

export default DeviceInfoForm;
