import Device from '../common/Device/';
import React from 'react';

interface Props {
    devicesData: any;
    onSelect: any;
}

const DevicesContainer: React.FC<Props> = ({devicesData, onSelect}) => {
    return <>
        <div className="contentContainer">
            <h3 className='mobileCentered'>Sell your phone</h3>
        </div>
        <div className='devicesContainer'>
            {devicesData.map((deviceData: any) => <Device
                key={deviceData.name}
                data={deviceData}
                onClick={e => onSelect(deviceData)}
            />)}
        </div>
    </>
}

export default DevicesContainer;
