import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Icon, InfoBox, Button, Divider } from '../common';
import DevicesReview from './DevicesReview';
import Models from '../../types/models';
import history from '../../helpers/history';

import './index.scss';

const OrderSummary: React.FC = () => {
    const {devices} = useSelector((state: Models.AppState) => state.currentOrder)

    if(devices.length === 0) return <Redirect to={'/'} />

    return <main className="contentArea">
        <div className="orderSummaryContainer">
            <section className='orderSummaryForm'>
                <DevicesReview items={devices} />
            </section>

            <section className='orderSummary'>
                <div className="orderSummaryDetails">
                    <h4>Order summary</h4>

                    <div className="itemsPrices">
                        {
                            devices.map(({description: {name, price}}, index) => (
                                <div
                                    className="itemNameAndPrice"
                                    key={index}
                                >
                                    <span>{name}</span>
                                    <span>${price}</span>
                                </div>
                            ))
                        }
                    </div>

                    <Divider />

                    <div className="totalOrderPrice">
                        <span>Total payout</span>
                        <span className='totalPrice'>${devices.reduce((p, c) => {return p + c.description.price}, 0)}</span>
                    </div>

                    <InfoBox text={'Please note, we do not pay for devices that have been reported lost or stolen.'} />

                    <div className="buttonsContainer">
                        <>
                            <Button label={'Continue to checkout'} click={() => history.push('/shipping')} className={'wide primary filled tablet-big-visible'}/>
                            <Button label={'Add another device'} click={() => history.push('/')} className={'wide primary'}/>
                        </>
                    </div>

                    <div className='orderSummaryParams'>
                        <div className='paramItem'>
                            <Icon type={'money'}/>
                            <span>14 day offer guarantee</span>
                        </div>
                        <div className='paramItem'>
                            <Icon  type={'lock'}/>
                            <span>Secure payment</span>
                        </div>
                    </div>
                </div>

                <p className='orderSummaryInfoText'>
                    We reserve the right to reject the device, or make an adjusted offer, if the device does not confirm to the description you provided or for other reasons written in our terms and conditions.
                </p>
            </section>
        </div>
    </main>
};

export default OrderSummary;
