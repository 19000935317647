import React, {useEffect} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import './index.scss';

interface Props {
    isVisible: boolean;
    youtubeVideoUrl: string;
    onClose: () => void;
}

const VideoPopup: React.FC<Props> = ({youtubeVideoUrl, onClose, isVisible}) => {
    const handleClose = (e: any) => { if(e.code === 'Escape') onClose() };

    useEffect(() => {
        document.addEventListener('keyup', handleClose, true);

        return () => document.removeEventListener('keyup', handleClose, true);
    }, [])

    return <div className={`videoPopupContainer ${isVisible ? 'visible' : ''}`}>
        {/*<span className="closeBtn">X</span>*/}
        <div className="outsideClickArea">
            <OutsideClickHandler
                onOutsideClick={() => onClose()}
            >
                <iframe
                    id="ytplayer" width="100%" height="100%"
                    src={youtubeVideoUrl}
                    frameBorder="0"
                />
            </OutsideClickHandler>
        </div>
    </div>;
}

export default VideoPopup;