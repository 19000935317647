import Models from '../../types/models';

export enum CURRENT_DEVICE_INFO_FILLING_ACTIONS {
    DEVICE_SELECTION = 'DEVICE_SELECTION',
    SELECT_MEMORY = 'SELECT_MEMORY',
    SELECT_CARRIER = 'SELECT_CARRIER',
    UPDATE_CONDITIONS = 'UPDATE_CONDITIONS',
    SET_INFO_FORM_STEP = 'SET_INFO_FORM_STEP',
    CLEAR_INFO = 'CLEAR_INFO',
    EDIT_DEVICE_STORAGE = 'EDIT_DEVICE_STORAGE',
    EDIT_DEVICE_CARRIER = 'EDIT_DEVICE_CARRIER',
    EDIT_DEVICE_CONDITIONS = 'EDIT_DEVICE_CONDITIONS',
}

export enum ORDER_ACTIONS {
    ADD_DEVICE = 'ADD_DEVICE',
    ADD_SHIPPING_USER = 'ADD_SHIPPING_USER',
    ADD_SHIPPING_INSURANCE = 'ADD_SHIPPING_INSURANCE',
    ADD_SHIPPING_KIT = 'ADD_SHIPPING_KIT',
    ADD_PAYMENT = 'ADD_PAYMENT',
    ADD_PAYMENT_SPEED = 'ADD_PAYMENT_SPEED',
    REMOVE_DEVICE = 'REMOVE_DEVICE',
    FINALIZE_ORDER = 'FINALIZE_ORDER'
}

class AddDeviceToOrder {
    readonly type = ORDER_ACTIONS.ADD_DEVICE;
    readonly payload: Models.DeviceDescription = {} as Models.DeviceDescription;
}

class RemoveDeviceFromOrder{
    readonly type = ORDER_ACTIONS.REMOVE_DEVICE;
    readonly payload: string = '';
}

class AddPaymentToOrder {
    readonly type = ORDER_ACTIONS.ADD_PAYMENT;
    readonly payload: Models.PaymentDetails = {} as Models.PaymentDetails;
}

class AddPaymentSpeedToOrder {
    readonly type = ORDER_ACTIONS.ADD_PAYMENT_SPEED;
    readonly payload: boolean = false;
}

class AddShippingUserDetailsToOrder {
    readonly type = ORDER_ACTIONS.ADD_SHIPPING_USER;
    readonly payload: Models.User = {} as Models.User;
}

class AddShippingInsuranceToOrder {
    readonly type = ORDER_ACTIONS.ADD_SHIPPING_INSURANCE;
    readonly payload: boolean = true;
}

class AddShippingKitToOrder {
    readonly type = ORDER_ACTIONS.ADD_SHIPPING_KIT;
    readonly payload: boolean = true;
}

class FinalizeOrder {
    readonly type = ORDER_ACTIONS.FINALIZE_ORDER;
}

export type ORDER_ACTION_TYPES = AddDeviceToOrder
    | AddPaymentToOrder
    | AddShippingUserDetailsToOrder
    | AddShippingInsuranceToOrder
    | AddPaymentSpeedToOrder
    | AddShippingKitToOrder
    | RemoveDeviceFromOrder
    | FinalizeOrder;

class EditDeviceStorage {
    readonly type = CURRENT_DEVICE_INFO_FILLING_ACTIONS.EDIT_DEVICE_STORAGE;
    readonly payload: Models.Device = {} as Models.Device;
}

class EditDeviceCarrier {
    readonly type = CURRENT_DEVICE_INFO_FILLING_ACTIONS.EDIT_DEVICE_CARRIER;
    readonly payload: Models.Device = {} as Models.Device;
}

class EditDeviceConditions {
    readonly type = CURRENT_DEVICE_INFO_FILLING_ACTIONS.EDIT_DEVICE_CONDITIONS;
    readonly payload: Models.Device = {} as Models.Device;
}

export type DEVICE_ACTION_TYPES = any
    | EditDeviceStorage
    | EditDeviceCarrier
    | EditDeviceConditions;

