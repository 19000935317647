enum ConditionValue {
    Yes = 'Yes',
    No = 'No',
    Unset = 'Unset',
}

enum OrderStatus {
    Submitted,
    Checking,
    Pending,
    Paid,
    Rejected,
}

enum DeviceStatus {
    Submitted,
    Checking,
    Pending,
    Paid,
    Rejected
}

export {
    ConditionValue,
    OrderStatus,
    DeviceStatus
}
