import React from 'react';
import {ReactComponent as Check} from '../../../assets/icons/check.svg';
import {ReactComponent as Close} from '../../../assets/icons/close.svg';
import './index.scss';

interface Props {
    toggleCheck: () => void,
    isSelected?: boolean,
    className?: string
}

const Switch: React.FC<Props> = (
    {
        toggleCheck,
        isSelected = false,
        className
    }) => <div
    className={`switch ${className ? className : ''} `}
    onClick={() => toggleCheck()}
>
    <div className={`switchUI ${isSelected ? 'active' : ''}`}>
        <Check/>
        <div className='switchHandle' />
        <Close/>
    </div>
</div>;

export default Switch;
