import Tip from '../../common/Tip';
import ButtonSelect from '../../common/ButtonSelect';
import React, {useState} from 'react';
import VideoPopup from "../../common/VideoPopup";

interface Props {
    memoryOptions: number[];
    onSelect: (value: number) => void;
}

const StorageSelect:React.FC<Props> = ({memoryOptions, onSelect}) => {
    const CHECK_YOUR_IPHONE_CAPACITY_VIDEO_URL = 'https://www.youtube.com/embed/Jm5jE50diJc&feature=youtu.be';
    const [videoPopupShown, setVideoPopupShown] = useState(false);

    return <>
        <div className="deviceInfoUp">
            <h3>Phone’s memory capacity</h3>
            <Tip label={'Help me to choose'}>
                <div>
                    Go to <span className='color-highlighted'>Settings</span> <span className='color-highlighted'>></span> <span className='color-highlighted'>General</span> <span className='color-light'>></span> <span className='color-highlight'>About</span>.
                    Scroll down to the bottom and you'll find storage capacity.
                </div>

                <a className={'watchVideoUrl'} onClick={() => setVideoPopupShown(true)}>
                    <span>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="9" cy="9" r="9" fill="#ECEAFF"/>
                            <path d="M11.5678 8.17985C12.1408 8.55615 12.1448 9.39154 11.5754 9.77323L8.49948 11.835C7.86045 12.2634 7 11.8077 7 11.0409L7 6.9591C7 6.1964 7.85227 5.73985 8.4919 6.1599L11.5678 8.17985Z" fill="#6254FF"/>
                        </svg>
                    </span>
                    <span>Watch video</span>
                </a>
            </Tip>

            <div className="deviceInfoParameterContainer">
                <div className="selectGroup">
                    {memoryOptions.map(el => <ButtonSelect key={el} click={() => onSelect(el)}><h3>{el} gb</h3></ButtonSelect>)}
                </div>
            </div>
        </div>

        <VideoPopup
            isVisible={videoPopupShown}
            onClose={() => setVideoPopupShown(false)}
            youtubeVideoUrl={CHECK_YOUR_IPHONE_CAPACITY_VIDEO_URL}
        />
    </>
}

export default StorageSelect;
