import React from 'react';
import './index.scss';
import Icon from '../Icon';
import { useLocation } from 'react-router';
import history from "../../../helpers/history";
import {Button} from "../index";
import {signOut} from "../../../api/firebase";

interface Props {
    label: string;
    path: string;
    isNextIconVisible?: boolean;
    isActive?: boolean;
}

const Breadcrumb: React.FC<Props> = ({label, path, isNextIconVisible = false, isActive = false}) => {
    return <div className={`breadcrumb ${isActive ? 'active' : ''}`} onClick={() => history.push(path)}>
        {isNextIconVisible && <Icon type={'arrow'} size={'small'} rotate={90}/>}
        <a href="">{label}</a>
    </div>
};

interface BreadcrumbsProps {
    crumbs: string[],
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({crumbs}) => {
    const {pathname} = useLocation();
    const BREADCRUMBS_ITEMS = [
        {label: 'Condition', path: '/info', isNextIconVisible: false},
        {label: 'Get offer', path: '/summary', isNextIconVisible: true},
        {label: 'Shipping', path: '/shipping', isNextIconVisible: true},
        {label: 'Payment', path: '/payment', isNextIconVisible: true}
    ];

    return <div className='breadcrumbs'>
        <div className="breadcrumbsContainer">
            {BREADCRUMBS_ITEMS.map((el, index) => (
                <Breadcrumb key={el.label} {...el} isActive={index <= crumbs.indexOf(pathname)}/>)
            )}
        </div>
        <Button label={'Sign out'} click={() => {
            signOut()
        }} />
    </div>
}

export default Breadcrumbs;
