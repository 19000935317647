const _DEV_PROD_CONFIG = {
    appName: "Swaptecs-app",
    apiKey: "AIzaSyDoFXiB7HrqnNGxqE0bB_w1_Rr4lk2yveg",
    authDomain: "swaptecs-app.firebaseapp.com",
    databaseURL: "https://swaptecs-app.firebaseio.com",
    projectId: "swaptecs-app",
    storageBucket: "swaptecs-app.appspot.com",
    messagingSenderId: "79476288658",
    appId: "1:79476288658:web:4293ea6408e17424a48a4b",
    measurementId: "G-BZYNT5HQ2C"
};

const _DEV_REMOTE_CONFIG = {
    appName: "Swaptecs-app-dev",
    apiKey: "AIzaSyDoFXiB7HrqnNGxqE0bB_w1_Rr4lk2yveg",
    authDomain: "swaptecs-app.firebaseapp.com",
    databaseURL: "https://swaptecs-app.firebaseio.com",
    projectId: "swaptecs-app",
    storageBucket: "swaptecs-app.appspot.com",
    messagingSenderId: "79476288658",
    appId: "1:79476288658:web:4293ea6408e17424a48a4b",
    measurementId: "G-BZYNT5HQ2C"
};

export const DEV_LOCAL_SETTINGS = {
    host: "localhost:8080",
    ssl: false
};


const _ENV_REMOTE = 'development';
const _ENV_PROD = 'production';

const _CONFIG_DICTIONARY: {[key: string]: any} = {
    [_ENV_PROD]: _DEV_PROD_CONFIG,
    [_ENV_REMOTE]: _DEV_REMOTE_CONFIG,
}


export const ORDERS_COLLECTION = 'orders';

export const getConfig = (config: string) => { return _CONFIG_DICTIONARY[config]; };
