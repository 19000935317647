import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeviceAction } from '../../redux/actions/formFilling';
import { Redirect } from 'react-router';
import DevicesContainer from './DevicesContianer';
import Models from '../../types/models';
import history from '../../helpers/history';

import './index.scss';

interface Props {}

const DeviceSelection: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const {currentDevice: {description}} = useSelector((state: Models.AppState) => state.currentOrder);

    // TODO: Remove data from here
    const devicesData = [
        {name: 'iPhone 12 Pro Max', imageUrl: 'ip12pm.png', price: 940},
        {name: 'iPhone 12 Pro', imageUrl: 'ip12p.png', price: 870},
        {name: 'Iphone 12', imageUrl: 'ip12.png', price: 800},
        {name: 'iPhone 12 mini', imageUrl: 'ip12m.png', price: 770},
        {name: 'iPhone SE, 2nd', imageUrl: 'ipSE2nd.png', price: 650},
        {name: 'iPhone 11 Pro Max', imageUrl: 'ip11pm.png', price: 640},
        {name: 'iPhone 11 Pro', imageUrl: 'ip11p.png', price: 620},
        {name: 'iPhone 11', imageUrl: 'ip11.png', price: 600},
        {name: 'iPhone XR', imageUrl: 'ipXR.png', price: 550},
        {name: 'iPhone XS Max', imageUrl: 'ipXSm.png', price: 540},
        {name: 'iPhone XS', imageUrl: 'ipXS.png', price: 800},
        {name: 'iPhone X', imageUrl: 'ipX.png', price: 700},
        {name: 'iPhone 8 Plus', imageUrl: 'ip8p.png', price: 600},
        {name: 'iPhone 8', imageUrl: 'ip8.png', price: 500},
        {name: 'iPhone 7 Plus', imageUrl: 'ip7p.png', price: 400},
        {name: 'iPhone 7', imageUrl: 'ip7.png', price: 300},
    ];

    const selectDevice = (deviceData: any) => {
        dispatch(selectDeviceAction(deviceData));
        history.push('/info');
    }

    if(!!description.name) return <Redirect to={'/info'} />;

    return <main className="contentArea devices">
        <DevicesContainer devicesData={devicesData} onSelect={selectDevice} />
    </main>;
};

export default DeviceSelection;
