import React, {useContext, useEffect} from 'react';
import {useLocation} from "react-router";
import {ROUTES} from '../../../constants';
import {AuthUserContext} from "../../../session";
import history from '../../../helpers/history';
import app from "../../../api/firebase";

const WithAuthorization: React.FC = ({children}) => {
    const authUser = useContext(AuthUserContext);
    const isLogin = useLocation().pathname === ROUTES.LOGIN;
    const pushLogin = () => !isLogin && history.push(ROUTES.LOGIN);

    useEffect(() => {
        const listener = app.auth().onAuthStateChanged(
            (user: any) => {
                if(!user) pushLogin()
            });
        return () => listener();
    }, [])

    return <>
        {authUser ? children : null}
    </>;
}

export default WithAuthorization;