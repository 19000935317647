import {ORDER_ACTION_TYPES, ORDER_ACTIONS} from '../actions/types';
import Models from '../../types/models';

import currentDeviceReducer from "./currentDeviceReducer";
import {DeviceStatus, OrderStatus} from "../../types/enums";
import history from "../../helpers/history";

export const CURRENT_DEVICE_INITIAL_STATE: Models.Device = {
    id: '',
    description: {} as Models.DeviceDescription,
    storage: 0,
    carrier: '',
    conditions: [] as Models.Conditions[],
    deviceInfoFormStep: 0,
    editMode: false,
    status: DeviceStatus.Submitted,
};

const ORDER_INITIAL_STATE: Models.Order = {
    currentDevice: CURRENT_DEVICE_INITIAL_STATE,
    devices: [] as Models.Device[],
    shippingDetails: {} as Models.ShippingDetails,
    paymentDetails: {isFastReviewSelected: false} as Models.PaymentDetails,
    confirmationDate: `${new Date().getTime()}`,
    status: OrderStatus.Submitted,
}

const orderReducer = (state = ORDER_INITIAL_STATE, action: ORDER_ACTION_TYPES) => {
    switch (action.type) {
        case ORDER_ACTIONS.ADD_SHIPPING_USER:
            return Object.assign({}, state, {
                shippingDetails: {...state.shippingDetails, user: action.payload}
            });
        case ORDER_ACTIONS.ADD_SHIPPING_INSURANCE:
            return Object.assign({}, state, {
                shippingDetails: {...state.shippingDetails, isInsuranceNecessary: action.payload}
            });
        case ORDER_ACTIONS.ADD_SHIPPING_KIT:
            return Object.assign({}, state, {
                shippingDetails: {...state.shippingDetails, isShippingKitNecessary: action.payload}
            });
        case ORDER_ACTIONS.ADD_DEVICE:
            return Object.assign({}, state, {devices: [...state.devices, state.currentDevice]});
        case ORDER_ACTIONS.ADD_PAYMENT_SPEED:
            return Object.assign({}, state, {
                paymentDetails: {
                    ...state.paymentDetails,
                    isFastReviewSelected: action.payload
                }
            });
        case ORDER_ACTIONS.REMOVE_DEVICE:
            return Object.assign({}, state, {devices: [...state.devices.filter((device) => device?.id !== action.payload)]});
        case ORDER_ACTIONS.FINALIZE_ORDER:
            history.push('/thank-you');
            return ORDER_INITIAL_STATE;
        default:
            return Object.assign({}, state, {currentDevice: currentDeviceReducer(state.currentDevice, action)});
    }
}

export default orderReducer;
