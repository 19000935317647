import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import {DEV_LOCAL_SETTINGS, getConfig, ORDERS_COLLECTION} from "./const";
import {OrderStatus} from "../../types/enums";

firebase.initializeApp(getConfig(process.env.NODE_ENV));

if(process.env.REACT_APP_FIREBASE_LOCAL === 'local') firebase.firestore().settings(DEV_LOCAL_SETTINGS);

const auth = firebase.auth();

/* ==== Order Creation ==== */
const submitOrder = async (order: any) => {
	const result = await firebase.firestore().collection(ORDERS_COLLECTION).add(order);
	// TODO add pre-loader to Thank you page
}

/* ==== Authorization ==== */
const signInWithEmailAndPassword = (email: string, password: string) =>
	auth.signInWithEmailAndPassword(email, password);

const signOut = () => auth.signOut();

const onAuthChange = (nextObserver: any, error?: any, complete?: any) => {
	return auth.onAuthStateChanged(nextObserver, error, complete);
}
/* ==== Administration ==== */
// Checking status is set when the order is now in development
const setOrderStatusToChecking = async (orderId: string, editor: string) => {
	return await firebase.firestore().collection(ORDERS_COLLECTION)
		.doc(orderId)
		.set({
			editor,
			status: OrderStatus.Checking,
		}, {merge: true});
};

// The order is closed when status is Paid and it can be closed only when payed to the customer
const closeOrder = async (orderId: string, editor: string) => {
	return await firebase.firestore().collection(ORDERS_COLLECTION)
		.doc(orderId)
		.set({
			editor,
			status: OrderStatus.Paid,
		}, {merge: true});
};

// TODO it 1: Base on the observable iteration 1!!!!!!!!!!!
const getOpenOrders = async () => {
	try {
		const result = await firebase.firestore()
			.collection(ORDERS_COLLECTION)
			.where('status', 'in', [OrderStatus.Submitted, OrderStatus.Checking, OrderStatus.Pending, OrderStatus.Paid])
			.get();
		return result.docs.map(doc => ({id: doc.id, ...doc.data()} || {}));
	} catch (e) {
		console.log(e);
	}
}

export default firebase;

export {
	submitOrder,
	signInWithEmailAndPassword,
	setOrderStatusToChecking,
	closeOrder,
	getOpenOrders,
	signOut,
	onAuthChange
}