import React from 'react';
import './index.scss';

interface Props {
    click: () => void;
    children: React.ReactNode;
}

const ButtonSelect:React.FC<Props> = ({click, children}) => {
    return <button
        className='buttonSelect'
        onClick={click}
    >
        {children}
    </button>
};

export default ButtonSelect;
