import React, {useState} from 'react';
import Tip from '../common/Tip';
import {SingleDeviceItemView} from '../common';
import {Button} from '../common';
import history from '../../helpers/history';

interface Props {
    items: any[];
}

const DevicesReview: React.FC<Props> = ({items}) => {
    const [expanded, setExpanded] = useState();

    return <div className='leftSideContainer'>
        <h3>Your trade-in offer</h3>
        <Tip label={'This is an estimation based on the information you left '}/>
        <Button label={'Continue to checkout'} click={() => history.push('/shipping')}
                className={'wide primary filled offer-mobile-button tablet-big-invisible'}/>

        <div className="itemsContainer">
            {
                items.map((itemData, index) => (
                    <SingleDeviceItemView
                        key={index}
                        isExpanded={index === expanded}
                        itemData={itemData}
                        click={() => index === expanded ? setExpanded(null) : setExpanded(index)}
                    />
                ))
            }
        </div>


        <p className="itemOfferExpiration">
            * Initial offer expires on 01/01/2020
        </p>
    </div>
}

export default DevicesReview;
