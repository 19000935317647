import React from 'react';
import './index.scss';

interface Props {
    data: any;
    onClick: (e: any) => void;
}

const Device: React.FC<Props> = ({data, onClick}) => {
    const {name, imageUrl, price} = data;
    return <div className='phoneContainer' onClick={onClick}>
        <div className="imageContainer">
            <img src={require(`../../../assets/phones/${imageUrl}`)} alt={name}/>
        </div>
        <p className={'name weight-medium'}>{name}</p>
        <p className={'price'}>Get up to ${price}</p>
    </div>
};

export default Device;
