import React, {useState} from 'react';
import Tip from '../common/Tip';
import {PaypalCard, WideSelectOption} from '../common';
import TextInput from '../common/TextInput';
import {Redirect} from "react-router";
import Models from "../../types/models";
import {useSelector} from "react-redux";

interface Props {
    paymentErrors: Array<string | null>;
    paypalEmail: any,
    setPaypalEmail: any,
    paypalEmailConfirm: any,
    setPaypalEmailConfirm: any,
    isFastInspection: boolean,
    setInspectionFast: (value: boolean) => void,
}

const PaymentInfo: React.FC<Props> = ({paymentErrors, paypalEmail, setPaypalEmail, paypalEmailConfirm, setPaypalEmailConfirm, isFastInspection, setInspectionFast}) => {
    // TODO: refactor validation
    const {currentOrder: {devices}} = useSelector((state: Models.AppState) => state);
    const SHIPPING_OPTIONS = [
        {
            title: 'Get paid more',
            label: 'Pay in 5 days after inspection complete'
        },
        {
            title: 'Get paid faster',
            titleHighlight: '(- $10)',
            label: '24-hour inspection after device received'
        }
    ];

    if(devices.length === 0) return <Redirect to={'/'} />

    return <div className='leftSideContainer'>
        <h3>Payment</h3>
        <Tip label={'We will pay you once we have inspected your device'} />

        <div className="formContainer payment">

            <div className="paymentFormGroup">
                <div className="paymentServiceCard">
                    <PaypalCard />
                </div>

                <div className="paymentForm">
                    <p>Please confirm PayPal email address so we can send the payment</p>

                    <div className="inputGroup">
                        <TextInput
                            name={'paypalEmail'}
                            value={paypalEmail}
                            label={'Paypal email address'}
                            placeholder={'Paypal email'}
                            type={'email'}
                            required={true}
                            error={paymentErrors[0] || ''}
                            onChange={(e) => setPaypalEmail(e.currentTarget.value)}
                        />
                        <TextInput
                            name={'paypalEmailConfirm'}
                            value={paypalEmailConfirm}
                            label={'Confirm Paypal email address'}
                            placeholder={'Your surname'}
                            type={'email'}
                            required={true}
                            error={paymentErrors[1] || ''}
                            onChange={(e) => setPaypalEmailConfirm(e.currentTarget.value)}
                        />
                    </div>
                </div>
            </div>
        </div>

        <div className="shippingPreference">
            <h4>Choose Preferred:</h4>
            {SHIPPING_OPTIONS.map((el, i) => <WideSelectOption
                key={i}
                isSelected={(i === 0 && !isFastInspection) || (i === 1 && isFastInspection)}
                onSelect={() => setInspectionFast(i !== 0)}
                optionData={el}
            />)}
        </div>
    </div>
}

export default PaymentInfo;
