import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const initialStore = JSON.parse(localStorage.getItem('appState') || '{}');

const store = createStore(rootReducer, initialStore, applyMiddleware(thunk));

store.subscribe(() => {
    localStorage.setItem('appState', JSON.stringify(store.getState()))
});

export default store;
