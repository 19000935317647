import { Icon } from '../index';
import React from 'react';

import './index.scss';

interface Props {
    text: string;
}

const InfoBox: React.FC<Props> = ({text}) => <div className="infoBoxContainer">
    <Icon type={'info'} />
    <p className={'infoBoxText'}>{text}</p>
</div>

export default InfoBox;
