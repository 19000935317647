import {DeviceStatus} from "../../../types/enums";
import React from "react";
import Models from "../../../types/models";

interface Props {
    device: Models.Device,
}

const OrderViewDevice: React.FC<Props> = ({device}) =>  {
    return <div key={device.id} className='orderDeviceCard'>
        <div className="orderDeviceInfo">
            <img src={require(`../../../assets/phones/${device.description.imageUrl}`)} alt="Device image"/>

            <div className="orderDeviceStats">
                <h3><span>{device.description.name}</span> <span className={`status ${DeviceStatus[device.status]}`}>{DeviceStatus[device.status]}</span></h3>
                <p><b>Price: </b>$ {device.description.price}</p>
                <p><b>Storage: </b>$ {device.storage}</p>
                <p><b>Carrier: </b>$ {device.carrier}</p>

                <h3>Conditions:</h3>
                <ul className='orderDeviceConditions'>
                    {device.conditions.map(condition => <li key={condition.title}>
                        <h5>{condition.title} - {condition.value}</h5>
                        {condition.valueExplanation && <p className='color-light'>{condition.valueExplanation}</p>}
                    </li>)}
                </ul>
            </div>
        </div>
    </div>
}

export default OrderViewDevice;